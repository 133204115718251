<template>
<div class="Pourparler">
  <div class="container">
    <h1 class="gavc-h1 text-center">Pourparlers</h1>
    <div class="row">
      <div class="col-12">
        <stepBar :labels="labels" :currentStep="Number(step)" :totalSteps="labels.length" />
      </div>
    </div>

    <div v-if="Number(step) === 1">
      <validationCartouche
        :hasCedantValidated="pourparler.cedant_has_validated_identification_parties"
        :hasRepreneurValidated="pourparler.repreneur_has_validated_identification_parties"
      />
      <pourparlerIdentificationParties
        :pourparler="pourparler"
        v-on:nextStep="nextStep"
        v-on:validatePourparler="validatePourparler(true)"
        v-on:unvalidatePourparler="validatePourparler(false)"
      />
    </div>

    <div v-if="Number(step) === 2">

      <validationCartouche
        :hasCedantValidated="pourparler.cedant_has_validated_valorisation"
        :hasRepreneurValidated="pourparler.repreneur_has_validated_valorisation"
      />
      <h2 class="gavc-h3">Valorisation de l'entreprise</h2>

      <p
        class="gavc-chapo"
      >
        Dans cette étape, négociez et accordez vous sur le prix de cession de l'entreprise (le cédant renseigne toutes les informations).
      </p>

      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.cedant-company-collapse>
        L'entreprise cédée
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>

      <b-collapse id="cedant-company-collapse" role="tabpanel">
        <CompanyItem :company="pourparler.candidature.offre_cession.entreprise_cedee" />
      </b-collapse>
      <hr>


      <h3 class="gavc-h3 mt-4"> Informations renseignées par <u>le cédant</u></h3>

      <div
        class="gavc-field"
        v-bind:class="{ 'is-error': $v.pourparler.price_total.$dirty ? $v.pourparler.price_total.$error : null  }"
      >
        <label class="gavc-label" for="pourparlers-step-pourparler.price_total-input">
          Prix total
          <span class="gavc-required"> * </span>
        </label>
        <div class="gavc-field gavc-field--icon">
          <input
            class="gavc-input gavc-input--icon gavc-input-strong-disabled"
            @input="$v.pourparler.price_total.$touch()"
            id="pourparlers-step-pourparler.price_total-input"
            placeholder="00.00"
            type="text"
            maxlength="12"
            :disabled="userGroupContains(['REPRENEUR']) || pourparler.cedant_has_validated_valorisation"
            v-model.lazy="maskedPriceTotal"
            v-money="maskedPriceTotalOptions"
          >
          <i class="gavc-icon gavc-icon-euro"></i>
        </div>
        <p v-if="$v.pourparler.price_total.$dirty ? $v.pourparler.price_total.$error : null" class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner le prix total
        </p>
      </div>

      <div
        class="gavc-field"
        v-bind:class="{ 'is-error': $v.pourparler.nb_parts_to_sell.$dirty ? $v.pourparler.nb_parts_to_sell.$error : null  }"
      >
        <label class="gavc-label" for="pourparlers-step-pourparler.nb_parts_to_sell-input">
          Nombre de parts sociales à céder
          <span class="gavc-required"> * </span>
          <small>(L'entreprise cédéé est composée de {{pourparler.candidature.offre_cession.entreprise_cedee.nb_titres_capital}} titres de capital)</small>
        </label>
        <div class="gavc-field">
          <input
            class="gavc-input gavc-input-strong-disabled"
            @input="$v.pourparler.nb_parts_to_sell.$touch()"
            id="pourparlers-step-pourparler.nb_parts_to_sell-input"
            placeholder="0"
            type="number"
            step="1"
            v-model="pourparler.nb_parts_to_sell"
            :disabled="userGroupContains(['REPRENEUR']) || pourparler.cedant_has_validated_valorisation"
          >
          <p v-if="$v.pourparler.nb_parts_to_sell.$dirty ? $v.pourparler.nb_parts_to_sell.$error : null" class="gavc-p gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner le nombre de parts à céder
          </p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div
            class="gavc-field"
            v-bind:class="{ 'is-error': $v.pourparler.nb_parts_sociales_detenues.$dirty ? $v.pourparler.nb_parts_sociales_detenues.$error : null  }"
          >
            <label class="gavc-label" for="pourparlers-step-pourparler.nb_parts_sociales_detenues-input">
              Nombre de parts sociales détenues
              <span class="gavc-required"> * </span>
            </label>
            <div class="gavc-field">
              <input
                class="gavc-input gavc-input-strong-disabled"
                @input="$v.pourparler.nb_parts_sociales_detenues.$touch()"
                id="pourparlers-step-pourparler.nb_parts_sociales_detenues-input"
                placeholder="0"
                type="number"
                step="1"
                v-model="pourparler.nb_parts_sociales_detenues"
                :disabled="userGroupContains(['REPRENEUR']) || pourparler.cedant_has_validated_valorisation"
              >
                <p v-if="$v.pourparler.nb_parts_sociales_detenues.$dirty ? $v.pourparler.nb_parts_sociales_detenues.$error : null" class="gavc-p gavc-text-rose">
                  <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner cette information
                </p>
            </div>
          </div>
        </div>
      </div>


      <p class="mt-2 gavc-question" v-if="pourparler.nb_parts_sociales_detenues && isUniqueAssocie">
        <strong v-if="userGroupContains(['CEDANT'])">Vous êtes donc l'unique associé de l'entreprise</strong>
        <strong v-else>Le cédant est l'unique associé de l'entreprise</strong>
      </p>
      <p class="mt-2 gavc-question gavc-text-rose" v-else-if="pourparler.nb_parts_sociales_detenues && !isUniqueAssocie">
        <strong v-if="userGroupContains(['CEDANT'])">Vous n'êtes donc pas l'unique associé de l'entreprise</strong>
        <strong v-else>Le cédant n'est pas l'unique associé de l'entreprise</strong>
      </p>



      <div class="gavc-field">
        <label class="gavc-label" for="pourparlers-step-pourparler.parts_acquisition_way-input">
          Vous possédez vos parts sociales
          <span class="gavc-required"> * </span>
        </label>
        <select class="gavc-select" v-model="pourparler.parts_acquisition_way" name="pourparlers-step-pourparler.parts_acquisition_way-input" :disabled="userGroupContains(['REPRENEUR']) || pourparler.cedant_has_validated_valorisation">
          <option v-for="option in partAcquisitionWayOptions" :key="option.value" class="gavc-select-option" :value="option.value">
            {{option.text}}
          </option>
        </select>
        <p v-if="$v.pourparler.parts_acquisition_way.$dirty ? $v.pourparler.parts_acquisition_way.$error : null " class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner cette information
        </p>
      </div>

      <div v-if="pourparler.parts_acquisition_way === 'ACQUIS'" class="mb-3">
        <div class="gavc-field">
          <label class="gavc-label" for="pourparlers-step-pourparler.date_parts_acquisition-input">
            Date d'acquisition des parts
            <span class="gavc-required"> * </span>
          </label>
          <div class="gavc-field gavc-field--icon">
            <input @input="$v.pourparler.date_parts_acquisition.$touch()" class="gavc-input gavc-input--icon gavc-input--calendar" id="pourparlers-step-pourparler.date_parts_acquisition-input" type="date"
              v-model="pourparler.date_parts_acquisition" :disabled="userGroupContains(['REPRENEUR']) || pourparler.cedant_has_validated_valorisation">
            <i class="gavc-icon gavc-icon-calendar"></i>
          </div>
          <p v-if="$v.pourparler.date_parts_acquisition.$dirty ? $v.pourparler.date_parts_acquisition.$error : null " class="gavc-p gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner cette information
          </p>
        </div>

        <div class="gavc-field"
          v-bind:class="{ 'is-error': $v.pourparler.price_total_acquisition.$dirty ? $v.pourparler.price_total_acquisition.$error : null  }"
        >
          <label class="gavc-label" for="pourparlers-step-pourparler.price_total_acquisition-input">
            Valeur d'acquisition par part
            <span class="gavc-required"> * </span>
          </label>
          <div class="gavc-field gavc-field--icon">
            <input
              class="gavc-input gavc-input--icon"
              @input="$v.pourparler.price_total_acquisition.$touch()"
              id="pourparlers-step-pourparler.price_total_acquisition-input"
              placeholder="0"
              type="number"
              maxlength="12"
              :disabled="userGroupContains(['REPRENEUR']) || pourparler.cedant_has_validated_valorisation"
              v-model="pourparler.price_total_acquisition"
            >
            <i class="gavc-icon gavc-icon-euro"></i>
          </div>
          <p v-if="$v.pourparler.price_total_acquisition.$dirty ? $v.pourparler.price_total_acquisition.$error : null" class="gavc-p gavc-text-rose">
            <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner cette information
          </p>
        </div>
      </div>

      <p class="gavc-label mt-3">
        Vos parts sociales sont des actions normales, offrant droit de vote et de dividendes ?
        <span class="gavc-required"> * </span>
      </p>
      <div class="gavc-field">
        <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
          <div class="gavc-radio-icon">
            <input :value="true" type="radio" id="pourparler.are_actions_normales-input-1"
                   name="pourparler.are_actions_normales-input-1" v-model="pourparler.are_actions_normales"
                   :disabled="userGroupContains(['REPRENEUR']) || pourparler.cedant_has_validated_valorisation">
            <label class="gavc-label" for="pourparler.are_actions_normales-input-1">
              Oui
            </label>
          </div>
          <div class="gavc-radio-icon">
            <input :value="false" type="radio" id="pourparler.are_actions_normales-input-2"
                   name="pourparler.are_actions_normales-input-2" v-model="pourparler.are_actions_normales"
                   :disabled="userGroupContains(['REPRENEUR']) || pourparler.cedant_has_validated_valorisation">
            <label class="gavc-label" for="pourparler.are_actions_normales-input-2">
              Non
            </label>
          </div>
        </div>
        <p v-if="$v.pourparler.are_actions_normales.$dirty ? $v.pourparler.are_actions_normales.$error : null" class="gavc-p gavc-text-rose">
          <i class="gavc-icon gavc-icon-cross" /> Veuillez renseigner cette information
        </p>
      </div>

      <p class="gavc-question gavc-text-rose" v-if="pourparler.are_actions_normales === false">
        <strong>Attention, veuillez consulter un avocat avant de continuer </strong>
      </p>

      <div v-if="userGroupContains(['CEDANT'])" class="row mb-4 mt-4">
        <div class="col-12 text-right">
          <button class="mt-2 gavc-btn gavc-btn--bone" @click="previousStep">
            Précédent
          </button>

          <span
            v-if="!pourparler.cedant_has_validated_valorisation"
            @mouseenter="$v.$touch()"
          >
            <button
              class="mt-2 ml-2 gavc-btn"
              @click="modifyPourparler"
              :disabled="isValorisationStepInvalid"
            >
              Enregistrer vos informations
            </button>
          </span>

          <button
            v-if="areValorisationInformationSavedAndValid && !pourparler.cedant_has_validated_valorisation"
            class="mt-2 ml-2 gavc-btn"
            @click="validatePourparler(true)"
          >
            Valider l'étape de valorisation
          </button>

          <button
            v-else-if="pourparler.cedant_has_validated_valorisation && !pourparler.has_cedant_validated && !pourparler.has_repreneur_validated"
            class="mt-2 ml-2 gavc-btn gavc-btn--bone"
            @click="validatePourparler(false)"
          >
            Invalider l'étape de valorisation
          </button>

          <button v-if="pourparler.cedant_has_validated_valorisation && !pourparler.repreneur_has_validated_valorisation" class="mt-2 ml-2 gavc-btn" :disabled="true">
            En attente de validation de la part du repreneur
          </button>

          <button v-if="pourparler.cedant_has_validated_valorisation && pourparler.repreneur_has_validated_valorisation" class="mt-2 ml-2 gavc-btn" @click="nextStep">
            Étape suivante
          </button>
        </div>
      </div>

      <div v-if="userGroupContains(['REPRENEUR'])" class="row mb-4 mt-4">
        <div class="col-12 text-right">
          <button class="mt-2 gavc-btn gavc-btn--bone" @click="previousStep">
            Précédent
          </button>
          <button 
            v-if="isValorisationStepInvalid || !pourparler.cedant_has_validated_valorisation" 
            class="mt-2 ml-2 gavc-btn" 
            :disabled="true"
          >
            En attente de remplissage et validation des informations par le cédant
          </button>
          <button
            v-else-if="!pourparler.repreneur_has_validated_valorisation"
            class="mt-2 ml-2 gavc-btn"
            @click="validatePourparler(true)"
          >
            Valider l'étape de valorisation de l'entreprise
          </button>
          <button
            v-else-if="pourparler.repreneur_has_validated_valorisation && !pourparler.has_repreneur_validated"
            class="mt-2 ml-2 gavc-btn gavc-btn--bone"
            @click="validatePourparler(false)"
          >
            Invalider l'étape de valorisation
          </button>
          <button v-else-if="pourparler.repreneur_has_validated_valorisation && pourparler.cedant_has_validated_valorisation" class="mt-2 ml-2 gavc-btn" @click="nextStep">
            Étape suivante
          </button>
        </div>
      </div>
    </div>

    <div v-if="Number(step) === 3">
      <validationCartouche
        :hasCedantValidated="pourparler.has_cedant_validated"
        :hasRepreneurValidated="pourparler.has_repreneur_validated"
      />

      <h2 class="gavc-h3">Finalisation</h2>

      <p
        class="gavc-chapo"
      >
        Dans cette étape, indiquez les modalités de financement du rachat de l'entreprise (le repreneur)
        et, si besoin, la composition de l'actionnariat (le cédant).
      </p>

      <div v-if="pourparler.has_cedant_validated && pourparler.has_repreneur_validated">
        <p class="gavc-chapo">
          🎉 <strong>Félicitations</strong>, l'étape des pourparlers est terminée, vous pouvez <strong>générer automatiquement
          votre lettre d'intention</strong> en cliquant sur le bouton ci-dessous.
        </p>

        <div class="row mb-4">
          <div class="col-12 text-center">

            <button
              class="gavc-btn"
              @click="downloadLettreIntention"
            >
              Générer la lettre d'intention
            </button>
          </div>
        </div>
      </div>


      <h3 class="gavc-h3">Informations à remplir par <u> le repreneur </u></h3>

      <p class="gavc-label mt-3">
        Moyens de financement
        <span class="gavc-required"> * </span>
      </p>

      <div
        class="gavc-field">
        <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
          <div class="gavc-radio-icon">
            <input
              @change="modifyPourparler"
              v-model="pourparler.financement_cession"
              value="PRET"
              type="checkbox"
              id="pourparler.financement_cession-checkbox-2"
              name="pourparler.financement_cession-checkbox-2"
              :disabled="userGroupContains(['CEDANT']) || pourparler.has_repreneur_validated"
            >
            <label class="gavc-label" for="pourparler.financement_cession-checkbox-2">
                Prêt bancaire
            </label>
          </div>
          <div class="gavc-radio-icon">
            <input
              @change="modifyPourparler"
              v-model="pourparler.financement_cession"
              value="APPORTS"
              type="checkbox"
              id="pourparler.financement_cession-checkbox-1"
              name="pourparler.financement_cession-checkbox-1"
              :disabled="userGroupContains(['CEDANT']) || pourparler.has_repreneur_validated"
            >
            <label class="gavc-label" for="pourparler.financement_cession-checkbox-1">
              Apports personnels
            </label>
          </div>
          <div class="gavc-radio-icon">
            <input
              @change="modifyPourparler"
              v-model="pourparler.financement_cession"
              value="SUBVENTIONS"
              type="checkbox"
              id="pourparler.financement_cession-checkbox-4"
              name="pourparler.financement_cession-checkbox-4"
              :disabled="userGroupContains(['CEDANT']) || pourparler.has_repreneur_validated"
            >
             <label class="gavc-label" for="pourparler.financement_cession-checkbox-4">
               Subventions et aides
             </label>
          </div>
          <div class="gavc-radio-icon">
            <input
              @change="modifyPourparler"
              v-model="pourparler.financement_cession"
              value="CREDIT"
              type="checkbox"
              id="pourparler.financement_cession-checkbox-3"
              name="pourparler.financement_cession-checkbox-3"
              :disabled="userGroupContains(['CEDANT']) || pourparler.has_repreneur_validated"
            >
            <label class="gavc-label" for="pourparler.financement_cession-checkbox-3">
              Crédit vendeur
            </label>
          </div>
          <div class="gavc-radio-icon">
            <input
              @change="modifyPourparler"
              v-model="pourparler.financement_cession"
              value="REPORT"
              type="checkbox"
              id="pourparler.financement_cession-checkbox-5"
              :disabled="userGroupContains(['CEDANT']) || pourparler.has_repreneur_validated"
              name="pourparler.financement_cession-checkbox-5"
            >
            <label class="gavc-label" for="pourparler.financement_cession-checkbox-5">
              Report contractuel du prix
            </label>
          </div>
        </div>
      </div>

      <div v-if="!isUniqueAssocie">
        <h3 class="gavc-h3 mt-4">Informations complémentaires à remplir par <u> le cédant </u></h3>
        <p class="gavc-chapo">
          Le cédant doit remplir des informations complémentaires car il n'est pas l'unique associé au sein
          de l'entreprise
        </p>


        <p class="gavc-label mt-3">
          Vos associés cèdent-ils aussi leurs parts ?
          <span class="gavc-required"> * </span>
        </p>
        <div class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon">
              <input :value="true" type="radio" id="pourparler.is_shareholders_selling_parts-input-1"
                     name="pourparler.is_shareholders_selling_parts-input-1" v-model="pourparler.is_shareholders_selling_parts"
                     :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                     @change="modifyPourparler">
              <label class="gavc-label" for="pourparler.is_shareholders_selling_parts-input-1">
                Oui
              </label>
            </div>
            <div class="gavc-radio-icon">
              <input :value="false" type="radio" id="pourparler.is_shareholders_selling_parts-input-2"
                     name="pourparler.is_shareholders_selling_parts-input-2" v-model="pourparler.is_shareholders_selling_parts"
                     :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                     @change="modifyPourparler">
              <label class="gavc-label" for="pourparler.is_shareholders_selling_parts-input-2">
                Non
              </label>
            </div>
          </div>
        </div>


        <p class="gavc-label mt-3">
          Les statuts de l'entreprise stipulent-ils une clause d’agrément ?
          <span class="gavc-required"> * </span>
        </p>
        <div class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon">
              <input :value="true" type="radio" id="pourparler.is_agrement_clause_in_status-input-1"
                     name="pourparler.is_agrement_clause_in_status-input-1" v-model="pourparler.is_agrement_clause_in_status"
                     :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                     @change="modifyPourparler">
              <label class="gavc-label" for="pourparler.is_agrement_clause_in_status-input-1">
                Oui
              </label>
            </div>
            <div class="gavc-radio-icon">
              <input :value="false" type="radio" id="pourparler.is_agrement_clause_in_status-input-2"
                     name="pourparler.is_agrement_clause_in_status-input-2" v-model="pourparler.is_agrement_clause_in_status"
                     :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                     @change="modifyPourparler">
              <label class="gavc-label" for="pourparler.is_agrement_clause_in_status-input-2">
                Non
              </label>
            </div>
          </div>
        </div>

        <div v-if="pourparler.is_agrement_clause_in_status" class="gavc-field">

          <p class="gavc-label mt-3">
            Le projet de cession est-il conforme avec les statuts ?
            <span class="gavc-required"> * </span>
          </p>
          <div class="gavc-field">
            <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
              <div class="gavc-radio-icon">
                <input :value="true" type="radio" id="pourparler.is_project_conforme_with_status-input-1"
                       name="pourparler.is_project_conforme_with_status-input-1" v-model="pourparler.is_project_conforme_with_status"
                       :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                       @change="modifyPourparler">
                <label class="gavc-label" for="pourparler.is_project_conforme_with_status-input-1">
                  Oui
                </label>
              </div>
              <div class="gavc-radio-icon">
                <input :value="false" type="radio" id="pourparler.is_project_conforme_with_status-input-2"
                       name="pourparler.is_project_conforme_with_status-input-2" v-model="pourparler.is_project_conforme_with_status"
                       :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                       @change="modifyPourparler">
                <label class="gavc-label" for="pourparler.is_project_conforme_with_status-input-2">
                  Non
                </label>
              </div>
            </div>
          </div>
        </div>


        <p class="gavc-label mt-3">
          Avez-vous conclu un pacte d’actionnaires/d’associés avec tout ou partie des associés ?
          <span class="gavc-required"> * </span>
        </p>
        <div class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon">
              <input :value="true" type="radio" id="pourparler.is_pacte_actionnaires-input-1"
                     name="pourparler.is_pacte_actionnaires-input-1" v-model="pourparler.is_pacte_actionnaires"
                     :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                     @change="modifyPourparler">
              <label class="gavc-label" for="pourparler.is_pacte_actionnaires-input-1">
                Oui
              </label>
            </div>
            <div class="gavc-radio-icon">
              <input :value="false" type="radio" id="pourparler.is_pacte_actionnaires-input-2"
                     name="pourparler.is_pacte_actionnaires-input-2" v-model="pourparler.is_pacte_actionnaires"
                     :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                     @change="modifyPourparler">
              <label class="gavc-label" for="pourparler.is_pacte_actionnaires-input-2">
                Non
              </label>
            </div>
          </div>
        </div>

        <div v-if="pourparler.is_pacte_actionnaires" class="gavc-field">
          <p class="gavc-label mt-3">
            Le pacte prévoit-il un agrément en cas de cession ?
            <span class="gavc-required"> * </span>
          </p>
          <div class="gavc-field">
            <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
              <div class="gavc-radio-icon">
                <input :value="true" type="radio" id="pourparler.is_agrement_clause_in_pacte-input-1"
                       name="pourparler.is_agrement_clause_in_pacte-input-1" v-model="pourparler.is_agrement_clause_in_pacte"
                       :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                       @change="modifyPourparler">
                <label class="gavc-label" for="pourparler.is_agrement_clause_in_pacte-input-1">
                  Oui
                </label>
              </div>
              <div class="gavc-radio-icon">
                <input :value="false" type="radio" id="pourparler.is_agrement_clause_in_pacte-input-2"
                       name="pourparler.is_agrement_clause_in_pacte-input-2" v-model="pourparler.is_agrement_clause_in_pacte"
                       :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                       @change="modifyPourparler">
                <label class="gavc-label" for="pourparler.is_agrement_clause_in_pacte-input-2">
                  Non
                </label>
              </div>
            </div>
          </div>
        </div>


        <div v-if="pourparler.is_agrement_clause_in_status && pourparler.is_project_conforme_with_status && pourparler.is_pacte_actionnaires" class="gavc-field">
          <p class="gavc-label mt-3">
            Vos associés sont-ils informés de votre projet de cession ?
            <span class="gavc-required"> * </span>
          </p>
          <div class="gavc-field">
            <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
              <div class="gavc-radio-icon">
                <input :value="true" type="radio" id="pourparler.is_shareholders_informed_cession-input-1"
                       name="pourparler.is_shareholders_informed_cession-input-1" v-model="pourparler.is_shareholders_informed_cession"
                       :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                       @change="modifyPourparler">
                <label class="gavc-label" for="pourparler.is_shareholders_informed_cession-input-1">
                  Oui
                </label>
              </div>
              <div class="gavc-radio-icon">
                <input :value="false" type="radio" id="pourparler.is_shareholders_informed_cession-input-2"
                       name="pourparler.is_shareholders_informed_cession-input-2" v-model="pourparler.is_shareholders_informed_cession"
                       :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                       @change="modifyPourparler">
                <label class="gavc-label" for="pourparler.is_shareholders_informed_cession-input-2">
                  Non
                </label>
              </div>
            </div>
          </div>
          <p v-if="pourparler.is_shareholders_informed_cession === false" class="gavc-question gavc-text-rose">
            <strong v-if="userGroupContains(['CEDANT'])">
              Attention, aux fins d’assurer la validité de votre cession, vous devez obtenir leur accord préalable
            </strong>
            <strong v-else>
              Attention, aux fins d’assurer la validité de votre cession, le cédant doit obtenir leur accord préalable
            </strong>
          </p>
        </div>



        <p class="gavc-label mt-3">
          Vos associés ont-ils accepté votre projet de cession ?
          <span class="gavc-required"> * </span>
        </p>
        <div class="gavc-field">
          <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
            <div class="gavc-radio-icon">
              <input :value="true" type="radio" id="pourparler.have_shareholders_accepted_project-input-1"
                     name="pourparler.have_shareholders_accepted_project-input-1" v-model="pourparler.have_shareholders_accepted_project"
                     :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                     @change="modifyPourparler">
              <label class="gavc-label" for="pourparler.have_shareholders_accepted_project-input-1">
                Oui
              </label>
            </div>
            <div class="gavc-radio-icon">
              <input :value="false" type="radio" id="pourparler.have_shareholders_accepted_project-input-2"
                     name="pourparler.have_shareholders_accepted_project-input-2" v-model="pourparler.have_shareholders_accepted_project"
                     :disabled="userGroupContains(['REPRENEUR']) || pourparler.has_cedant_validated"
                     @change="modifyPourparler">
              <label class="gavc-label" for="pourparler.have_shareholders_accepted_project-input-2">
                Non
              </label>
            </div>
          </div>
        </div>

        <p v-if="pourparler.have_shareholders_accepted_project === false" class="gavc-question gavc-text-rose">
          <strong>
            Attention, nous vous conseillons fortement de prendre un avocat
          </strong>
        </p>
      </div>

      <div v-if="!isUniqueAssocie && pourparler.has_cedant_validated && pourparler.has_repreneur_validated">
        <p class="gavc-chapo">
          Félicitations, l'étape des pourparlers est terminée, vous pouvez <strong>générer automatiquement
          votre lettre d'intention</strong> en cliquant sur le bouton ci-dessous.
        </p>

        <div class="row mb-4">
          <div class="col-12 text-center">

            <button
              class="gavc-btn"
              @click="downloadLettreIntention"
            >
              Générer la lettre d'intention
            </button>
          </div>
        </div>
      </div>

      <div v-if="userGroupContains(['CEDANT'])" class="row mb-4 mt-4">
        <div class="col-12 text-right">
          <button class="mt-2 gavc-btn gavc-btn--bone" @click="previousStep">
            Précédent
          </button>
          <button v-if="!isUniqueAssocie && (!pourparler.has_cedant_validated && !pourparler.has_repreneur_validated)"
                  class="mt-2 ml-2 gavc-btn" @click="modifyPourparler"
                  :disabled="isCedantFinalStepInvalid">
            Enregistrer vos informations
          </button>
          <button
            v-if="!isCedantFinalStepInvalid && !pourparler.has_cedant_validated"
            class="mt-2 ml-2 gavc-btn"
            @click="validatePourparler(true)"
          >
            Valider les pourparlers
          </button>

          <button
            v-else-if="pourparler.has_cedant_validated && !pourparler.has_repreneur_validated"
            class="mt-2 ml-2 gavc-btn gavc-btn--bone"
            @click="validatePourparler(false)"
          >
            Invalider l'étape
          </button>

          <button v-if="pourparler.has_cedant_validated && !pourparler.has_repreneur_validated" class="mt-2 ml-2 gavc-btn" :disabled="true">
            En attente de validation du repreneur
          </button>

          <button v-if="pourparler.has_cedant_validated && pourparler.has_repreneur_validated" class="mt-2 ml-2 gavc-btn" @click="$router.push('/cedant/tableau-de-bord')">
            Retour au tableau de bord pour accéder à l'audit
          </button>
        </div>
      </div>

      <div v-if="userGroupContains(['REPRENEUR'])" class="row mb-4 mt-4">
        <div class="col-12 text-right">
          <button class="mt-2 gavc-btn gavc-btn--bone" @click="previousStep">
            Précédent
          </button>
          <button v-if="!areRepreneurFinalInformationSavedAndValid" class="mt-2 ml-2 gavc-btn" @click="modifyPourparler"
                  :disabled="isRepreneurFinalStepInvalid">
            Enregistrer vos informations
          </button>

          <button
            v-else-if="!pourparler.has_repreneur_validated"
            class="mt-2 ml-2 gavc-btn"
            @click="validatePourparler(true)"
          >
            Valider les pourparlers
          </button>

          <button
            v-else-if="pourparler.has_repreneur_validated && !pourparler.has_cedant_validated"
            class="mt-2 ml-2 gavc-btn gavc-btn--bone"
            @click="validatePourparler(false)"
          >
            Invalider l'étape
          </button>

          <button v-if="pourparler.has_repreneur_validated && !pourparler.has_cedant_validated" class="mt-2 ml-2 gavc-btn" :disabled="true">
            En attente de validation du cédant
          </button>
          <button v-if="pourparler.has_repreneur_validated && pourparler.has_cedant_validated" class="mt-2 ml-2 gavc-btn" @click="$router.push('/repreneur/tableau-de-bord')">
            Retour au tableau de bord pour accéder à l'audit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  validationMixin
} from "vuelidate";
import {
  integer,
  minValue,
  maxValue,
  numeric,
  required,
  requiredIf
} from "vuelidate/lib/validators";

import {
  APIConnected
} from "@/api/APIConnected";
import {
  isDateOrNullOrBlank
} from '@/validators/validators'
import {
  fileOperations
} from "@/mixins/documentMixins";
import userMixins from "@/mixins/userMixins";
import uxMixins from "@/mixins/uxMixins";
import { defaultMoneyMaskOptions } from "@/variables/masks/moneyMasks"
import {
  partAcquisitionWayList
} from '@/variables/partAcquisitionWayList'
import {
  repreneurFinancementCessionList
} from '@/variables/repreneurFinancementCessionList'

const apiConnectedService = new APIConnected();
const stepBar = () => import("@/components/layout/stepBar");
const validationCartouche = () => import("@/components/layout/validationCartouche");

const CompanyItem = () => import("@/components/company/companyItem");
const pourparlerIdentificationParties = () => import("@/components/pourparler/pourparlerIdentificationParties")


export default {
  name: "Pourparler",
  components: {
    CompanyItem,
    pourparlerIdentificationParties,
    stepBar,
    validationCartouche
  },
  mixins: [fileOperations, userMixins, uxMixins, validationMixin],
  data: function() {
    return {
      labels: [
        "Identification des parties​",
        "Valorisation de l'entreprise",
        "Finalisation",
      ],
      areValorisationInformationSavedAndValid: false,
      areRepreneurFinalInformationSavedAndValid: false,
      maskedPriceTotal: null,
      maskedPriceTotalOptions: defaultMoneyMaskOptions,
      pourparler: {
        candidature: {
          audit: {
            id: "",
          },
          description: "",
          offre_cession: {
            entreprise_cedee: {
              cedant: {
                is_personne_physique: true,
                phone_number: "0666666666",
                user: {
                  first_name: "",
                  last_name: "",
                  username: "",
                },
              },
              capital: 0,
              procedure_collective: "NON",
              qualite: "ASSOCIE",
              date_creation: "1900-01-01",
              name: "",
              nb_titres_capital: 0,
              siren: "000000000",
            },
          },
          repreneur: {
            company: {
              name: "",
              nb_titres_capital: 0,
              siren: "000000000"
            },
            is_personne_physique: true,
            phone_number: "0666666666",
            user: {
              first_name: "",
              last_name: "",
              username: "",
            },
          },
          state: "3VALIDATED",
        },
        has_cedant_validated: false,
        has_repreneur_validated: false,
        cedant_has_validated_identification_parties: false,
        repreneur_has_validated_identification_parties: false,
        cedant_has_validated_valorisation: false,
        repreneur_has_validated_valorisation: false,

        nb_parts_sociales_detenues: null,
        are_actions_normales: null,
        parts_acquisition_way: null,
        date_parts_acquisition: null,
        price_total_acquisition: null,
        nb_parts_to_sell: null,
        price_total: null,

        is_shareholders_selling_parts: null,
        is_agrement_clause_in_status: null,
        is_project_conforme_with_status: null,
        is_pacte_actionnaires: null,
        is_agrement_clause_in_pacte: null,
        is_shareholders_informed_cession: null,
        have_shareholders_accepted_project: null,

        financement_cession: null

      },

      partAcquisitionWayOptions: partAcquisitionWayList,
      repreneurFinancementCessionOptions: repreneurFinancementCessionList,
      step: 1,
    }
  },
  computed: {
    hasValidatedPourparlers() {
      return (
        (this.userGroupContains(["CEDANT"]) &&
          this.pourparler.has_cedant_validated) ||
        (this.userGroupContains(["REPRENEUR"]) &&
          this.pourparler.has_repreneur_validated)
      );
    },
    isUniqueAssocie() {
      if (this.pourparler.nb_parts_sociales_detenues) {
        if (Number(this.pourparler.candidature.offre_cession.entreprise_cedee.nb_titres_capital) ===
          (Number(this.pourparler.nb_parts_sociales_detenues))) {
          return true
        }
        return false
      }
      return null
    },
    token() {
      return this.$store.state.auth.token;
    },
    isValorisationStepInvalid() {
      if (this.$v.pourparler.nb_parts_sociales_detenues.$invalid ||
        this.$v.pourparler.are_actions_normales.$invalid || this.$v.pourparler.parts_acquisition_way.$invalid ||
        this.$v.pourparler.date_parts_acquisition.$invalid || this.$v.pourparler.price_total_acquisition.$invalid ||
        this.$v.pourparler.nb_parts_to_sell.$invalid || this.$v.pourparler.price_total.$invalid) {
        return true
      }
      return false
    },

    isCedantFinalStepInvalid() {
      if (this.$v.pourparler.is_shareholders_informed_cession.$invalid || this.$v.pourparler.is_shareholders_selling_parts.$invalid ||
        this.$v.pourparler.have_shareholders_accepted_project.$invalid || this.$v.pourparler.is_agrement_clause_in_status.$invalid ||
        this.$v.pourparler.is_pacte_actionnaires.$invalid || this.$v.pourparler.is_project_conforme_with_status.$invalid ||
        this.$v.pourparler.is_agrement_clause_in_pacte.$invalid) {
        return true
      }
      return false
    },

    isRepreneurFinalStepInvalid() {
      if (this.$v.pourparler.financement_cession.$invalid) {
        return true
      }
      return false
    }
  },
  created: function() {
    this.$dayjs.locale("fr");
    this.scrollToTop()
    this.getPourparler()

  },
  watch: {
    maskedPriceTotal(value) {
      if (typeof value === 'string' || value instanceof String) {
        this.pourparler.price_total = Number(value.replace(/ /g,''))
      }
    }
  },
  validations: {
    pourparler: {
      are_actions_normales: {
        required,
      },
      date_parts_acquisition: {
        required: requiredIf(function() {
          return this.pourparler.parts_acquisition_way === 'ACQUIS'
        }),
        validDate(value) {
          return isDateOrNullOrBlank(value)
        }
      },
      nb_parts_sociales_detenues: {
        required,
        integer,
        minValue: minValue(0),
        maxValue: maxValue(100000000000000)
      },
      parts_acquisition_way: {
        required,
      },
      price_total_acquisition: {
        required: requiredIf(function() {
          return this.pourparler.parts_acquisition_way === 'ACQUIS'
        }),
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100000000000000)
      },

      nb_parts_to_sell: {
        required,
        integer,
        minValue: minValue(0),
        maxValue: maxValue(10000)
      },
      price_total: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue: maxValue(100000000000000)
      },

      is_shareholders_informed_cession: {
        required: requiredIf(function() {
          return !this.isUniqueAssocie && this.is_agrement_clause_in_status && this.is_project_conforme_with_status && this.is_pacte_actionnaires
        }),
      },
      is_shareholders_selling_parts: {
        required: requiredIf(function() {
          return !this.isUniqueAssocie
        }),
      },
      have_shareholders_accepted_project: {
        required: requiredIf(function() {
          return !this.isUniqueAssocie
        }),
      },

      is_agrement_clause_in_status: {
        required: requiredIf(function() {
          return !this.isUniqueAssocie
        }),
      },
      is_pacte_actionnaires: {
        required: requiredIf(function() {
          return !this.isUniqueAssocie
        }),
      },
      is_project_conforme_with_status: {
        required: requiredIf(function() {
          return !this.isUniqueAssocie && this.is_agrement_clause_in_status
        }),
      },
      is_agrement_clause_in_pacte: {
        required: requiredIf(function() {
          return !this.isUniqueAssocie && this.is_pacte_actionnaires
        }),
      },
      financement_cession : {
        required
      }
    }
  },
  methods: {
    downloadLettreIntention() {
      apiConnectedService
        .downloadLettreIntention(this.token, this.pourparler.id)
        .then(
          (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "lettre_intention.docx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          },
          () => {
            this.$store.commit("openGlobalErrorModal");
          }
        );
    },

    getPourparler() {
      this.$store.commit("loadFromAPI");
      apiConnectedService
        .getPourparler(this.token, this.$route.params.pourparlerId)
        .then((result) => {
          this.pourparler = result.data
          if (this.pourparler.price_total_acquisition) {
            this.pourparler.price_total_acquisition = Number(this.pourparler.price_total_acquisition)
          }
          if (this.pourparler.price_total) {
            this.maskedPriceTotal = Number(this.pourparler.price_total)
          }

          if (!this.isValorisationStepInvalid) {
            this.areValorisationInformationSavedAndValid = true
          }
          if (!this.isRepreneurFinalStepInvalid) {
            this.areRepreneurFinalInformationSavedAndValid = true
          }


          if (this.pourparler.cedant_has_validated_identification_parties && this.pourparler.repreneur_has_validated_identification_parties) {
            this.step = 2
          }
          if (this.pourparler.cedant_has_validated_valorisation && this.pourparler.repreneur_has_validated_valorisation) {
            this.step = 3
          }
        })
        .catch((error) => {
          this.$store.commit("openGlobalErrorModal");
          throw new Error(
            "Pourparler getPourparler API Error : " + String(error)
          );
        })
        .finally(() => {
          this.$store.commit("endLoadingFromAPI");
        });
    },

    modifyPourparler() {
      this.$store.commit("loadFromAPI");
      apiConnectedService
        .modifyPourparler(
          this.token,
          this.$route.params.pourparlerId,
          this.pourparler
        )
        .then((result) => {
          this.pourparler = result.data
          if (this.pourparler.price_total_acquisition) {
            this.pourparler.price_total_acquisition = Number(this.pourparler.price_total_acquisition)
          }
          if (this.pourparler.price_total) {
            this.maskedPriceTotal = Number(this.pourparler.price_total)
          }
          if (!this.isValorisationStepInvalid) {
            this.areValorisationInformationSavedAndValid = true
          }
          if (!this.isRepreneurFinalStepInvalid) {
            this.areRepreneurFinalInformationSavedAndValid = true
          }
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast(
            "Vos informations transmises dans les pourparlers ont bien été mises à jour", {
              id: 'notification-toast',
              title: "Informations mises à jour",
              variant: "success",
              solid: true,
            }
          );
        })
        .catch((error) => {
          this.$store.commit("openGlobalErrorModal");
          throw new Error(
            "Pourparler modifyPourparler API Error : " + String(error)
          );
        })
        .finally(() => {
          this.$store.commit("endLoadingFromAPI");
        });
    },

    nextStep() {
      this.step ++
      this.scrollToTop()
    },
    previousStep() {
      this.step --
      this.scrollToTop()
    },
    validatePourparler(validationStatus) {
      this.$store.commit("loadFromAPI");

      const data = {};
      if (this.userGroupContains(["CEDANT"])) {
        if (this.step == 1) {
          data["cedant_has_validated_identification_parties"] = validationStatus
        }
        if (this.step == 2) {
          // This is because we have a bug, when cedant modfiy field and click directly on validatePourparler
          for(var k in this.pourparler) data[k] = this.pourparler[k]
          data["cedant_has_validated_valorisation"] = validationStatus
        }
        if (this.step == 3) {
          data["has_cedant_validated"] = validationStatus
        }
      } else if (this.userGroupContains(["REPRENEUR"])) {
        if (this.step == 1) {
          data["repreneur_has_validated_identification_parties"] = validationStatus
        }
        if (this.step == 2) {
          data["repreneur_has_validated_valorisation"] = validationStatus
        }
        if (this.step == 3) {
          data["has_repreneur_validated"] = validationStatus
        }
      }
      apiConnectedService
        .modifyPourparler(this.token, this.$route.params.pourparlerId, data)
        .then((result) => {
          this.pourparler = result.data
          this.scrollToTop()
          if (this.pourparler.price_total_acquisition) {
            this.pourparler.price_total_acquisition = Number(this.pourparler.price_total_acquisition)
          }
          if (!this.isValorisationStepInvalid) {
            this.areValorisationInformationSavedAndValid = true
          }
          if (!this.isCedantFinalStepInvalid) {
            this.areCedantFinalInformationSavedAndValid = true
          }
          if (!this.isRepreneurFinalStepInvalid) {
            this.areRepreneurFinalInformationSavedAndValid = true
          }

          if (validationStatus) {
            this.$bvToast.toast(
              "Félicitations, votre validation a bien été enregistrée", {
                title: "Validation prise en compte",
                variant: "success",
                solid: true,
              }
            )
          }
          else {
            this.$bvToast.toast(
              "Vous avez bien invalidé l'étape, vous pouvez effectuer les modifications nécessaires, échanger avec l'autre partie puis à nouveau valider cette étape", {
                title: "Invalidation de l'étape",
                variant: "danger",
                solid: true,
              }
            )
          }

        })
        .catch((error) => {
          this.$store.commit("openGlobalErrorModal");
          throw new Error(
            "Pourparler validatePourparler API Error : " + String(error)
          );
        })
        .finally(() => {
          this.$store.commit("endLoadingFromAPI");
        });
    },
  },
};
</script>
