export const repreneurFinancementCessionList = [
  {
    "text": "Prêt bancaire​",
    "value": "PRET"
  },
  {
    "text": "Apports personnels",
    "value": "APPORTS"
  },
  {
    "text": "Subventions et aides",
    "value": "SUBVENTIONS"
  },
  {
    "text": "Crédit vendeur​​",
    "value": "CREDIT"
  },
  {
    "text": "Report contractuel du prix",
    "value": "REPORT"
  }
]
