export const partAcquisitionWayList = [
  {
    "text": "Depuis la création",
    "value": "CREATION"
  },
  {
    "text": "Par acquisition ou héritage",
    "value": "ACQUIS"
  }
]
